import {
  Flag,
  ProviderDetails,
  ProviderSubCollectionReportReportType,
} from '#/types';
import { DataCardConfig } from '#/types/data-card-config.types';
import { isEmpty } from 'lodash';
import {
  cardIdSectionMap,
  CredSteps,
  CredStepsDisplayLabel,
  MatchConditionParams,
  MatchConditionsParams,
  ShowActionCredSteps,
  StatusActions,
} from './Constants';

export const getInitialsFromString = (text: string) => {
  if (!text) return null;
  const parts = text.split(' ');
  return parts
    .map((part) => {
      if (!part.trim()) return '';
      return part.trim()[0].toUpperCase();
    })
    .join('');
};

export const hasRoles = (userRoles: string[], requiredRoles: string[]) => {
  if (requiredRoles.length == 0) return true;
  return requiredRoles.reduce((result: boolean | undefined, role: string) => {
    if (result === true) return true;
    return userRoles && userRoles.includes(role);
  }, false);
};

export const isActionAudited = (currentStatus: string, updatedStatus: string) =>
  (ShowActionCredSteps.includes(updatedStatus) &&
    currentStatus != updatedStatus) ||
  (updatedStatus === CredSteps.PSV_COMPLETE_BY_CERTIFYOS &&
    currentStatus === CredSteps.PSV_READY);

export const getWorkflowStatusAction = (
  currentStatus: string,
  updatedStatus: string,
) => {
  if (updatedStatus === CredSteps.PSV_READY)
    return StatusActions.MOVE_TO_PSV_READY;
  else if (updatedStatus === CredSteps.PROVIDER_TERMINATED)
    return StatusActions.TERMINATE;
  else if (
    updatedStatus === CredSteps.PSV_COMPLETE_BY_CERTIFYOS &&
    currentStatus === CredSteps.PSV_READY
  )
    return StatusActions.MOVE_TO_PSV_COMPLETE_BY_CERTIFYOS;
  else return StatusActions.WITHDRAW_CANCELED;
};

export const getCardSectionFlagFields = (cardConfig: DataCardConfig) => {
  return Object.keys(cardConfig.fields || {})
    .map((fieldId: string) => {
      const fieldConfig = (cardConfig.fields || {})[fieldId];
      return {
        id: fieldId,
        config: fieldConfig,
      };
    })
    .filter((field: any) => {
      return field.config.type === 'chip';
    });
};

export interface ProviderFlagsData {
  flag: Flag;
  cardDetails: {
    [field: string]: any;
  };
  cardConfig: DataCardConfig;
}

export const extractProviderFlagData = (
  providerDetails: ProviderDetails,
  config: DataCardConfig[],
): ProviderFlagsData[] => {
  return config
    .map((cardConfig) => {
      // @ts-ignore
      const dataRows = providerDetails[cardConfig.id];
      const flagFields = getCardSectionFlagFields(cardConfig);
      return dataRows
        .map((data: any) => {
          return flagFields
            .filter((fieldConfig) => !!data[fieldConfig.id])
            .map((fieldConfig) => {
              return {
                flag: data[fieldConfig.id],
                cardDetails: data,
                cardConfig,
              };
            });
        })
        .flat();
    })
    .flat();
};

export const checkIfFlagsAreSynced =
  (cardId: string, flagFields: any[], data: any) =>
  (_result: any): boolean => {
    // @ts-ignore
    const cardDataRows: any[] = data && data[cardId];
    const allFlagsSynced = flagFields.reduce((acc: boolean, field: any) => {
      if (!acc) return acc;
      let hasFlag = false;
      let hasFlagSynced = false;
      cardDataRows.forEach((row: any) => {
        const fieldData = row[field.id];
        if (!isEmpty(fieldData)) {
          hasFlag = true;
          hasFlagSynced = fieldData.synced;
        }
      });
      if (hasFlag) {
        acc = !!hasFlagSynced;
      }
      return acc;
    }, true);
    return !allFlagsSynced;
  };

const wait = function (ms = 1000) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};
// polls a function until condition is met (i.e, until fnCondition function returns true)
export const poll = async function (
  fn: () => Promise<any>,
  fnCondition: (result: any) => boolean,
  ms = 10000,
  maxCalls = 10,
) {
  console.log('polling for new data...');
  let result = await fn();
  let counter = 0;
  while (counter <= maxCalls && fnCondition(result)) {
    counter++;
    await wait(ms);
    result = await fn();
  }
  return result;
};

export const stringCompareFn = (v1: string, v2: string) => {
  return v1.localeCompare(v2);
};

export const unflatten = (data: any) => {
  const result = {};
  for (const i in data) {
    const keys = i.split('.');
    keys.reduce(function (v1: any, v2: any, j) {
      return (
        v1[v2] ||
        (v1[v2] = isNaN(Number(keys[j + 1]))
          ? keys.length - 1 == j
            ? data[i]
            : {}
          : [])
      );
    }, result);
  }
  return result;
};

export const getCredStepDisplayLabel = (value: string): string => {
  return value ? CredStepsDisplayLabel[value] ?? value : '';
};

export const getProviderAttachmentFileType = ({
  subCollectionName,
  data,
}: {
  subCollectionName: ProviderSubCollectionReportReportType;
  data: { type?: string };
}) => {
  if (
    [
      cardIdSectionMap.training,
      cardIdSectionMap.affiliation,
      cardIdSectionMap.dea,
    ].includes(subCollectionName)
  ) {
    {
      return data?.type;
    }
  }

  return subCollectionName;
};
export const matchCondition = (params: MatchConditionParams): boolean => {
  const {
    condition,
    userRoles,
    hasEditProvider,
    isCompleted,
    psvFileSignedUrl,
    onStep,
    credCycle,
    canGenerateNpdbPdf,
  } = params;
  let isValid = true;

  if (condition.hasEditProvider !== undefined) {
    isValid = isValid && hasEditProvider === condition.hasEditProvider;
  }
  if (condition.isCompleted !== undefined) {
    isValid = isValid && isCompleted === condition.isCompleted;
  }
  if (condition.psvFileSignedUrlExists !== undefined) {
    isValid =
      isValid &&
      (condition.psvFileSignedUrlExists
        ? !!psvFileSignedUrl
        : !psvFileSignedUrl);
  }
  if (condition.onStepTitle) {
    isValid =
      isValid &&
      !!onStep &&
      onStep.toLowerCase() === condition.onStepTitle.toLowerCase();
  }

  if (condition.credCycle) {
    isValid = isValid && !!credCycle && credCycle === condition.credCycle;
  }

  if (!isEmpty(condition.roles)) {
    isValid = isValid && !!hasRoles(userRoles, condition.roles!);
  }

  if (condition.canGenerateNpdbPdf) {
    isValid = isValid && Boolean(canGenerateNpdbPdf);
  }

  return isValid;
};

export const verifyConditions = (params: MatchConditionsParams) => {
  const { conditions, ...rest } = params;
  if (!conditions) return true;
  let response = true;

  if (conditions.must) {
    response =
      response &&
      conditions.must.reduce((result, condition) => {
        return result && matchCondition({ condition, ...rest });
      }, true);
  }
  if (conditions.should) {
    response =
      response &&
      conditions.should.reduce((result, condition) => {
        return result || matchCondition({ condition, ...rest });
      }, false);
  }

  if (conditions.mustNot) {
    response =
      response &&
      conditions.mustNot.reduce((result, condition) => {
        return result && !matchCondition({ condition, ...rest });
      }, true);
  }
  return response;
};
